<template>
    <div class="k-container">
        <Navbar />
        <div class="child-container">
            <div class="box">
                <b-card
                    bg-variant="kalkin-1"
                    style="max-width: 50rem"
                    text-variant="white"
                    ><b-row class="mt-4"
                        ><b-col cols="12"
                            ><h2>Créer un compte utilisateur</h2></b-col
                        ></b-row
                    ><b-row class="mt-4"
                        ><b-col cols="12"
                            ><b-form-input
                                placeholder="Nom d'utilisateur"
                                v-model="username"
                            ></b-form-input></b-col
                    ></b-row>
                    <b-row class="mt-4"
                        ><b-col cols="12"
                            ><b-form-input
                                placeholder="Email"
                                v-model="mail"
                            ></b-form-input></b-col
                    ></b-row>
                    <b-row class="mt-4"
                        ><b-col cols="12"
                            ><b-form-input
                                type="password"
                                placeholder="Mot de passe"
                                v-model="password"
                            ></b-form-input></b-col
                    ></b-row>
                    <b-row class="mt-4"
                        ><b-col cols="12"
                            ><b-form-input
                                type="password"
                                placeholder="Confirmer Mot de passe"
                                :state="state"
                                v-model="confirmpassword"
                            ></b-form-input></b-col
                    ></b-row>
                    <b-row class="mt-4"
                        ><b-col cols="12"
                            ><b-form-input
                                placeholder="Organisme"
                                v-model="organism"
                            ></b-form-input></b-col></b-row
                    ><b-row class="mt-4"
                        ><b-col cols="12"
                            ><b-button
                                class="custom-btn-secondary"
                                v-on:click="create"
                                >Créer l'utilisateur</b-button
                            ></b-col
                        ></b-row
                    ></b-card
                >
            </div>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/layouts/Navbar.vue";

export default {
    data: function () {
        return {
            username: "",
            mail: "",
            password: "",
            confirmpassword: "",
            organism: "",
            state: null,
        };
    },

    components: {
        Navbar,
    },
    watch: {
        password: function () {
            if (
                this.confirmpassword.length === 0 ||
                this.password.length === 0
            ) {
                this.state = null;
            } else if (this.password === this.confirmpassword) {
                this.state = true;
            } else {
                this.state = false;
            }
        },
        confirmpassword: function () {
            if (
                this.confirmpassword.length === 0 ||
                this.password.length === 0
            ) {
                this.state = null;
            } else if (this.password === this.confirmpassword) {
                this.state = true;
            } else {
                this.state = false;
            }
        },
    },
    methods: {
        create: async function () {
            if (this.state === true) {
                await this.$store.dispatch("user/create", {
                    username: this.username,
                    mail: this.mail,
                    password: this.password,
                    organism: this.organism,
                });
            }
        },
    },
};
</script>
<style>
.k-container {
    height: 100%;
    width: 100%;
}
.child-container {
    display: flex; /* establish flex container */
    justify-content: center; /* center items vertically, in this case */
    align-items: center; /* center items horizontally, in this case */
    height: 93%;
    width: 100%;
}
.box {
    width: 600px;
    margin: 5px;
    text-align: center; /* will center text in <p>, which is not a flex item */
}
.custom-btn-secondary {
    color: white;
    background-color: #92bb3f;
    border: #92bb3f;
}
.custom-btn-secondary:hover {
    color: white;
    background-color: #a3d145;
    border: white;
}
</style>
