<template>
    <b-navbar
        toggleable="lg"
        type="dark"
        :style="{ 'background-color': '#00817b' }"
    >
        <b-navbar-brand href="#"
            ><img src="../../../public//logo_navbar.png"
        /></b-navbar-brand>
        <b-navbar-nav class="ml-auto">
            <b-nav-item v-on:click="goWiki"
                ><b-icon-book scale="1.2"></b-icon-book
            ></b-nav-item>
            <b-nav-item v-on:click="goHome"
                ><b-icon-house scale="1.2"></b-icon-house
            ></b-nav-item>
            <b-nav-item-dropdown right>
                <template #button-content>
                    <em
                        ><b-icon-person-circle
                            scale="1.2"
                        ></b-icon-person-circle
                    ></em>
                </template>
                <b-dropdown-item
                    v-on:click="goCreateUser"
                    v-if="user.credentials == 10"
                    >Ajouter un utilisateur</b-dropdown-item
                >
                <b-dropdown-item v-on:click="logout"
                    >Déconnexion
                    <b-icon-box-arrow-in-right></b-icon-box-arrow-in-right
                ></b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </b-navbar>
</template>
<script>
export default {
    data: function () {
        return {
            user: "",
        };
    },
    beforeMount() {
        this.user = this.$store.state.user.user;
    },
    methods: {
        goHome: function () {
            this.$router.push({ name: "Home" });
        },
        goWiki: function () {
            this.$router.push({ name: "Wiki" });
        },
        goCreateUser: function () {
            this.$router.push({ name: "CreateUser" });
        },

        logout: function () {
            this.$store.dispatch("user/logout");
            this.$router.push({ name: "Login" });
        },
    },
};
</script>
